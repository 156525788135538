import styled from "styled-components";
import SectionHeader from "../common/SectionHeader";
import { device } from "../../device/index";

const AboutContainer = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right, #8080801f, #79554800, #4382771c);
`;

const ContentContainer = styled.div``;

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

const AboutTagLine = styled.p`
  border-left: 8px solid #0f1b33e0;
  color: #0f1b33e0;
  font-size: 2rem;
  padding: 0.6rem;
  font-weight: bold;

  @media ${device.mobileS} {
    font-size: 1.5rem;
  }
`;

const AboutContent = styled.p`
  font-size: 1.2rem;
  padding: 0 2rem 2rem;
  line-height: 1.5;
  word-spacing: 2px;
  text-align: justify;
  @media ${device.mobileS} {
    font-size: 1rem;
  }
`;

const About = ({ aboutRef }) => {
  return (
    <AboutContainer ref={aboutRef}>
      <SectionHeader heading="ABOUT" />
      <ContentContainer>
        <TagContainer>
          <AboutTagLine>Mission-driven React JS developer</AboutTagLine>
        </TagContainer>

        <AboutContent>
          I’ve always sought out opportunities and challenges that are
          meaningful to me. Although my professional path has taken many twists
          and turns — from Core PHP to React JS, from small projects to big
          projects. <br />
        </AboutContent>
      </ContentContainer>
    </AboutContainer>
  );
};
export default About;
