import styled from 'styled-components';
import { device } from '../../../device/index';

const HeadingContainer = styled.div`
	background: #0f1b33e0;
	height: 100px;
	align-items: center;
	display: flex;
	padding-left: 2rem;
`;
const SectionHeading = styled.p`
	font-size: 2.5rem;
	color: white;
	@media ${device.mobileS}{
		font-size: 2rem;
	} 
`;

const SectionHeader = (props) => {
    const {heading} = props
	return (
		<HeadingContainer>
			<SectionHeading>{heading}</SectionHeading>
		</HeadingContainer>
	);
};
export default SectionHeader;
