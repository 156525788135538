import styled from "styled-components";

const NavContainer = styled.div``;
const NavList = styled.ul``;
const NavItem = styled.li`
  list-style-type: none;
`;
const NavButton = styled.button`
  width: 100%;
  border: none;
  background: none;
  color: white;
  padding: 1rem;
  margin: 0.5rem 0;
  font-size: 1.2rem;
  &:hover,
  &:active,
  &:focus {
    border-left: 5px solid white;
    cursor: pointer;
    background: rgba(20, 20, 20, 0.1);
    outline: none;
    font-weight: 600;
    transition: all 0.2s;
  }
`;
const scrollTo = (ref) => {
  window.scroll({
    top: ref.current.offsetTop,
    behavior: "smooth",
  });
};

const NavItems = ({
  aboutRef,
  experienceRef,
  projectsRef,
  skillsRef,
  educationRef,
  open,
}) => {
  return open ? (
    <NavContainer>
      <NavList>
        <NavItem>
          <NavButton onClick={() => scrollTo(aboutRef)}>About</NavButton>
        </NavItem>
        <NavItem>
          <NavButton onClick={() => scrollTo(experienceRef)}>
            Experience
          </NavButton>
        </NavItem>
        <NavItem>
          <NavButton onClick={() => scrollTo(projectsRef)}>Projects</NavButton>
        </NavItem>
        <NavItem>
          <NavButton onClick={() => scrollTo(skillsRef)}>Skills</NavButton>
        </NavItem>
        <NavItem>
          <NavButton onClick={() => scrollTo(educationRef)}>
            Education
          </NavButton>
        </NavItem>
        {/* <NavItem>
          <NavButton >Contact</NavButton>
        </NavItem>
        <NavItem>
          <NavButton >Videos</NavButton>
        </NavItem> */}
      </NavList>
    </NavContainer>
  ) : (
    <></>
  );
};
export default NavItems;
