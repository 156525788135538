import styled from "styled-components";
import SectionHeading from "../common/SectionHeader";
import CustomCard from "../common/CustomCard/index";
import Divider from "../Divider/index";

const EducationContainer = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right, #0f1b330d, #7955481a);
`;

const QualificationName = styled.h2`
  color: #0f1b33;
`;

const CollegeName = styled.h3``;

const QualDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const qualification = [
  {
    name: "Master of Computer Application: 2017 with 8.40 CGPA",
    clg_name: "Guru Nanak Dev University College- Jalandhar, Punjab, India",
  },
  {
    name: "Bachelor of Computer Application: 2014 with 67.5%",
    clg_name:
      "Saraswati College of Computer Education- Jalalabad West, Punjab, India",
  },
  {
    name: "Senior Secondary (12th): 2011 with 79.1%",
    clg_name: "Shivalik Sen. Sec. School- Jalalabad West, Punjab, India",
  },
  {
    name: "Metriculation (10th): 2009 with 68.94%",
    clg_name: "Shivalik Sen. Sec. School- Jalalabad West, Punjab, India",
  },
];

const Education = ({educationRef}) => {
  return (
    <EducationContainer ref={educationRef}>
      <SectionHeading heading="EDUCATION" />
      <QualDetail>
        {(qualification || []).map((qual) => (
          <CustomCard flex="45%">
            <QualificationName>
              {qual?.name || ''}
            </QualificationName>
            <Divider />
            <CollegeName>
              {qual?.clg_name || ''}
            </CollegeName>
          </CustomCard>
        ))}
      </QualDetail>
    </EducationContainer>
  );
};
export default Education;
