import styled from 'styled-components';

const CardContainer = styled.div`
	padding: ${props => props.padding ? props.padding : '1rem'};
	text-align: center;
	box-shadow: 0px 0px 8px 0 rgb(0 0 0 / 42%);
	margin: 1rem;
	flex: ${props => props.flex ? props.flex : '25%'}
`;

const CustomCard = (props) => {
	const { children, padding, flex } = props;
	return <CardContainer padding={padding} flex={flex}>{children}</CardContainer>;
};
export default CustomCard;
