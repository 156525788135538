import PersonalDetails from "./components/PersonalDetails";
import BannerImage from "./components/BannerImage";
import NavItems from "./components/NavItems";
import Divider from "./components/Divider";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import styled from "styled-components";
import Education from "./components/Education/index";
import { useRef } from "react";
import { device } from "./device";
import Burger from "./components/Burger";
import { useState } from "react";

const MainContainer = styled.div`
  display: flex;
`;
const MobileNav = styled.div`
  width: 200px;
  float: right;
  background: #fff;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-10%)'};
  transition: transform 0.2s ease-in-out;
`;
const TopNavItems = styled.div`
  width: 100%;
  @media ${device.laptop}{
    display: none;
  }
`;
const HamBurger = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
`;
const LeftSideBar = styled.div`
  position: fixed;
  width: 200px;
  height: 100vh;
  background: #0f1b33;
  @media ${device.mobileS} {
    display: none;
  }
  @media ${device.laptop} {
    display: block;
  }
`;
const ContentArea = styled.div`
  width: 100%;
  margin-left: 200px;
  height: 100vh;

  @media ${device.mobileS} {
    margin-left: 0;
  }
  @media ${device.laptop} {
    margin-left: 200px;
  }
`;

function App() {
  const aboutRef = useRef(null);
  const experienceRef = useRef(null);
  const projectsRef = useRef(null);
  const skillsRef = useRef(null);
  const educationRef = useRef(null);
  const [open, setOpen] = useState(false);
  return (
    <MainContainer>
      <LeftSideBar>
        <PersonalDetails />
        <Divider />
        <NavItems
          aboutRef={aboutRef}
          experienceRef={experienceRef}
          projectsRef={projectsRef}
          skillsRef={skillsRef}
          educationRef={educationRef}
          open={true}
        />
      </LeftSideBar>
      <ContentArea>
        <TopNavItems>
          <HamBurger>
            <Burger open={open} setOpen={setOpen} />
          </HamBurger>
          <MobileNav open={open}>
          <NavItems
            aboutRef={aboutRef}
            experienceRef={experienceRef}
            projectsRef={projectsRef}
            skillsRef={skillsRef}
            educationRef={educationRef}
            open={open}
          />
          </MobileNav>
        </TopNavItems>
        <BannerImage />
        <About aboutRef={aboutRef} />
        <Experience experienceRef={experienceRef} />
        <Projects projectsRef={projectsRef} />
        <Skills skillsRef={skillsRef} />
        <Education educationRef={educationRef} />
      </ContentArea>
    </MainContainer>
  );
}
export default App;
