import styled from "styled-components";
import SectionHeading from "../common/SectionHeader";
import CustomCard from "../common/CustomCard";
import { device } from "../../device";

const SkillsContainer = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right, #0f1b330d, #7955481a);
`;

const SkillsDescription = styled.div`
  display: flex;
  @media ${device.mobileS}{
    flex-wrap: nowrap;
    flex-direction: column;
  }
  @media ${device.tablet}{
    flex-wrap: wrap;
    flex-direction: row;
    & > div{
      flex: 22%;
     }
  }
  @media ${device.laptopL}{
    & > div{
      flex: 16%;
     }
  }
`;

const TechLogoContainer = styled.div`
  margin: 1rem;
`;

const TechLogo = styled.img`
  width: 100%;
`;
const SkillName = styled.p`
  padding: 0.5rem;
  background: #0f1b33;
  color: white;
  font-size: 1.5rem;
`;

const skills_details = [
  {
    name: "React JS",
    logo: "/logo/react-logo.svg",
  },
  {
    name: "React Redux",
    logo: "/logo/redux-logo.png",
  },
  // {
  //   name: "Redux Saga",
  //   logo: "/logo/redux-saga.png",
  // },
  // {
  //   name: "CSS",
  //   logo: "/logo/css3.png",
  // },
  {
    name: "Styled Component",
    logo: "/logo/styled.png",
  },
  {
    name: "React Bootstrap",
    logo: "/logo/react-bootstrap.png",
  },
  // {
  //   name: "Semantic UI",
  //   logo: "/logo/semantic-ui.png",
  // },
  {
    name: "Material UI",
    logo: "/logo/material-ui.png",
  },
  {
    name: "Storybook",
    logo: "/logo/storybook.png",
  },
  {
    name: "Chakra UI",
    logo: "/logo/chakra-ui.png",
  },
  {
    name: "Git",
    logo: "/logo/logo-git.svg",
  },
  {
    name: "PHP MySQL",
    logo: "/logo/php-mysql.png",
  },
];

const Skills = ({skillsRef}) => {
  return (
    <SkillsContainer ref={skillsRef}>
      <SectionHeading heading="SKILLS" />
      <SkillsDescription>
        {(skills_details || []).map((skill, index) => {
          const { logo, name } = skill || {};
          return (
            <CustomCard key={index} padding="0px" flex="14%">
              <TechLogoContainer>
                <TechLogo src={logo} />
              </TechLogoContainer>
              <SkillName>{name}</SkillName>
            </CustomCard>
          );
        })}
      </SkillsDescription>
    </SkillsContainer>
  );
};
export default Skills;
