import styled from "styled-components";

const HorizontalDivider = styled.hr`
  background-color: #598473a6;
  width: 70%;
  margin: 1rem auto;
  height: 2px;
  border: none;
`;

const Divider = () => {
  return <HorizontalDivider></HorizontalDivider>;
};

export default Divider;
