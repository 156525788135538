import styled from "styled-components";

const PersonalSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  justify-content: center;
`;
const PersonName = styled.p`
  font-size: 2rem;
  color: white;
`;
const Designation = styled.p`
  font-size: 1.1rem;
  color: white;
`;

const ProfileContainer = styled.div`
  width: 100px;
  height: auto;
`;
const ProfilePhoto = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const PersonalDetails = () => {
  return (
    <>
      <PersonalSection>
        <ProfileContainer>
          <ProfilePhoto src="/images/my.jpg"></ProfilePhoto>
        </ProfileContainer>
        <PersonName>Rohit Chugh</PersonName>
        <Designation>React JS Developer</Designation>
      </PersonalSection>
    </>
  );
};
export default PersonalDetails;
