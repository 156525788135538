import styled from 'styled-components';
import SectionHeading from '../common/SectionHeader';
import ExperienceChart from '../common/OrganisationChart';
import CustomCard from '../common/CustomCard';
import Divider from '../Divider';
import { device } from '../../device';

const ExperienceContainer = styled.div`
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	background-image: linear-gradient(to right, #0f1b330d, #7955481a);
`;

const ExperienceContent = styled.div`padding: 2rem;`;

const CompanyName = styled.p`
	color: #0f1b33;
	font-size: 1.2rem;
	font-weight: 600;
	font-style: italic;
`;

const Designation = styled.p`font-size: 1.1rem;`;

const Duration = styled.p`
	font-size: 1.1rem;
	font-weight: 600;
	color: #0f1b33;
`;

const ExperienceSummary = styled.div`
display: flex;
@media ${device.mobileS}{
	flex-direction: column;
}
@media ${device.laptop}{
	flex-direction: row;
}
`;
const CurrentStyle = styled.p`
	color: red;
	display: inline;
`;
const companyDetails = [
	{
		company_name: 'TueSpot Solutions Pvt. Ltd. - Jalalabad(W)',
		designation: 'Full Stack PHP Web Developer',
		duration: 'July 2017 to April 2019'
	},
	{
		company_name: 'AppWrk IT Solutions Pvt. Ltd. - Mohali',
		designation: 'Software Engineer',
		duration: 'April 2019 to September 2020'
	},
	{
		company_name: 'Zestgeek Solutions Pvt. Ltd. - Mohali',
		designation: 'Front-End React JS Developer',
		duration: <> September 2020 to <CurrentStyle>Current</CurrentStyle></>
	}
];
const Experience = ({experienceRef}) => {
	return (
		<ExperienceContainer ref={experienceRef}>
			<SectionHeading heading="EXPERIENCE" />
			<ExperienceContent>
				<ExperienceChart />
				<ExperienceSummary>
					{(companyDetails || []).map((comp, index) => {
						const { company_name, designation, duration } = comp;
						return (
							<CustomCard key={index}>
								<CompanyName>{company_name}</CompanyName>
								<Divider />
								<Designation>{designation}</Designation>
								<Divider />
								<Duration>{duration}</Duration>
							</CustomCard>
						);
					})}
				</ExperienceSummary>
			</ExperienceContent>
		</ExperienceContainer>
	);
};
export default Experience;
