import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';

const StyledNode = styled.div`
	padding: 0.8rem;
	border-radius: 8px;
	display: inline-block;
	border: 2px solid #0f1b33;
	font-size: 1.2rem;
`;

const OrgChart = () => {
	return (
		<Tree lineColor="#0f1b33" lineWidth={'2px'} lineBorderRadius={'10px'} label={<StyledNode>4 Years</StyledNode>}>
			<TreeNode label={<StyledNode>2 Years (Core PHP)</StyledNode>} />
			<TreeNode label={<StyledNode>2 Years (React JS)</StyledNode>} />
		</Tree>
	);
};
export default OrgChart;
