import styled, { keyframes } from "styled-components";

const ImageContainer = styled.div`
  height: 100vh;
  background: url(/images/banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

const banner_text_animate = keyframes`
0%{
  -webkit-clip-path: ellipse(120px 120px at -2.54% -9.25%)
 clip-path: ellipse(120px 120px at -2.54% -9.25%)
}
50%{
 -webkit-clip-path: ellipse(120px 120px at 49.66% 64.36%);
 clip-path: ellipse(120px 120px at 49.66% 64.36%);

}
100%{
  -webkit-clip-path: ellipse(120px 120px at 102.62% -1.61%;);
 clip-path: ellipse(120px 120px at 102.62% -1.61%);
}
`;

const BannerText = styled.p`
  color: white;
  display: inline-block;
  &:before {
    content: attr(data-text);
    position: absolute;
    background: linear-gradient(#ffffff, #09152d87);
    -webkit-background-clip: text;
    color: transparent;
    background-size: 100% 90%;
    clip-path: ellipse(120px 120px at -2.54% -9.25%);
    animation: ${banner_text_animate} 4s infinite;
    animation-direction: alternate;
  }
`;

const BannerTextContainer = styled.div`
  font-size: 3rem;
  line-height: 1.5;
  position: absolute;
  top: 10px;
  padding: 20px;
  text-align: center;
  font-weight: 600;
`;

const BannerImage = () => {
  return (
    <>
      <ImageContainer />
      <BannerTextContainer>
        <BannerText data-text="I want to make things">
          I want to make things
        </BannerText>
        <br />
        <BannerText data-text="that make a difference">
          that make a difference.
        </BannerText>
      </BannerTextContainer>
    </>
  );
};
export default BannerImage;
