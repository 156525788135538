import styled from "styled-components";
import SectionHeading from "../common/SectionHeader";
import CustomCard from "../common/CustomCard";
import { device } from "../../device";

const ProjectContainer = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right, #0f1b330d, #7955481a);
`;
const ProjectContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${device.mobileS} {
    flex-direction: column;
  }

  @media ${device.laptopL} {
    flex-direction: row;
    & > div {
      flex: 32%;
    }
  }
`;
const ProjectThumbnail = styled.img`
  width: 100%;
  height: 100%;
`;
const ProjectName = styled.p`
  font-size: 1.2rem;
  color: #0f1b33;
  font-weight: 600;
`;
const ProjectDescription = styled.p``;
const ThumbContainer = styled.div`
  height: 85%;
`;
const DescContainer = styled.div`
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
const Project_details = [
  {
    project_thumb: "/images/carbonate.jpeg",
    project_name: "Carbonate (HR Management)",
    project_desc:
      "Experimental web app with homemade recursive string interpolation.",
    link: "https://carbonateapp.com/",
  },
  {
    project_thumb: "/images/raregames.jpg",
    project_name: "RareGames (E-Commerce)",
    project_desc:
      "Experimental web app with homemade recursive string interpolation.",
    link: "www.raregames.store/",
  },
  {
    project_thumb: "/images/autopros.png",
    project_name: "AutoProsFinder (Mobile Vehicle Services)",
    project_desc:
      "Experimental web app with homemade recursive string interpolation.",
    link: "https://autoprosfinder.com/",
  },
  {
    project_thumb: "/images/nbse.jpg",
    project_name: "National Board of School Education",
    project_desc:
      "Experimental web app with homemade recursive string interpolation.",
    link: "http://nbse.in/",
  },
];

const Projects = ({ projectsRef }) => {
  return (
    <ProjectContainer ref={projectsRef}>
      <SectionHeading heading="RECENT PROJECTS" />
      <ProjectContent>
        {(Project_details || []).map((project, index) => {
          const { project_thumb, project_name, link } = project;
          return (
            <CustomCard key={index}>
              <StyledAnchor href={link} target="_blank" rel="noreferrer">
                <ThumbContainer>
                  <ProjectThumbnail src={project_thumb} />
                </ThumbContainer>
                <DescContainer>
                  <ProjectName>{project_name}</ProjectName>
                  {/* <ProjectDescription>{project_desc}</ProjectDescription> */}
                </DescContainer>
              </StyledAnchor>
            </CustomCard>
          );
        })}
      </ProjectContent>
    </ProjectContainer>
  );
};
export default Projects;
